<template>
  <OrderList></OrderList>
</template>
<script>

import OrderList from './list'
export default {
  name: 'VirtualGiftList',
  components: {
    OrderList
  },
  data() {
    return {}
  },
  computed: {

  },
  methods: {},
  mounted() { }
}
</script>
<style scoped lang="css">
</style>
